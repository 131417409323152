import request from '@/utils/requests';
import { cloneDeep as _cloneDeep } from 'lodash';

const getters = {
  isCreateMode: (state) => state.isCreateMode,

  getUser: (state) => state.user,

  getUsername: (state) => state.user.username,

  getUserFirstName: (state) => state.user.first_name,

  getUserLastName: (state) => state.user.last_name,

  getUserOfficialRole: (state) => state.user.official_role,

  getUserRole: (state) => state.user.role,

  getUserAboutInfo: (state) => state.user.about_info,

  getUserImage: (state) => state.userImage,

  getUserToSave: (state) => {
    const user = _cloneDeep(state.user);
    const imageData = _cloneDeep(state.userImage);

    if (imageData) {
      user.image = {
        body: imageData.body,
        name: imageData.name,
      };
    }

    return user;
  },
};

const mutations = {
  SET_IS_CREATE_MODE: (state, isCreateMode) => {
    state.isCreateMode = isCreateMode;
  },

  SET_USER: (state, user) => {
    state.user = user;
  },

  RESET_USER: (state) => {
    state.user = {
      username: '',
      first_name: '',
      last_name: '',
      official_role: '',
      role: '',
      about_info: '',
    };
  },

  SET_USERNAME: (state, username) => {
    state.user.username = username;
  },

  SET_USER_FIRST_NAME: (state, firstName) => {
    state.user.first_name = firstName;
  },

  SET_USER_LAST_NAME: (state, lastName) => {
    state.user.last_name = lastName;
  },

  SET_USER_OFFICIAL_ROLE: (state, officialRole) => {
    state.user.official_role = officialRole;
  },

  SET_USER_ROLE: (state, role) => {
    state.user.role = role;
  },

  SET_USER_ABOUT_INFO: (state, aboutInfo) => {
    state.user.about_info = aboutInfo;
  },

  SET_USER_IMAGE: (state, userImage) => {
    state.userImage = userImage;
  },

  RESET_USER_IMAGE: (state) => {
    state.userImage = null;
  },
};

const actions = {
  startCreateMode: ({ commit }) => {
    commit('SET_IS_CREATE_MODE', true);
  },

  setUsername: ({ commit }, username) => {
    commit('SET_USERNAME', username);
  },

  setUserFirstName: ({ commit }, firstName) => {
    commit('SET_USER_FIRST_NAME', firstName);
  },

  setUserLastName: ({ commit }, lastName) => {
    commit('SET_USER_LAST_NAME', lastName);
  },

  setUserOfficialRole: ({ commit }, officialRole) => {
    commit('SET_USER_OFFICIAL_ROLE', officialRole);
  },

  setUserRole: ({ commit }, role) => {
    commit('SET_USER_ROLE', role);
  },

  setUserAboutInfo: ({ commit }, aboutInfo) => {
    commit('SET_USER_ABOUT_INFO', aboutInfo);
  },

  setUserImage: ({ commit }, userImage) => {
    commit('SET_USER_IMAGE', userImage);
  },

  endCreateMode: ({ commit }) => {
    commit('SET_IS_CREATE_MODE', false);
    commit('RESET_USER');
    commit('RESET_USER_IMAGE');
  },

  // eslint-disable-next-line no-shadow
  saveCreatedUser: async ({ getters, dispatch, rootGetters }) => {
    const user = getters.getUserToSave;
    const companyId = rootGetters['auth/getUserCompanyId'];

    try {
      await request(
        'POST',
        'api/users/',
        rootGetters['auth/getToken'],
        user,
        { company_id: companyId },
      );
      dispatch('endCreateMode');
      dispatch('users/setShouldRefetchUsers', true, { root: true });
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  },
};

const modules = {};

const state = {
  isCreateMode: false,
  user: {
    username: '',
    first_name: '',
    last_name: '',
    official_role: '',
    role: '',
    about_info: '',
  },
  userImage: null,
};

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  modules,
  state,
};
