import request from '@/utils/requests';

const getters = {
  fetchStatus: (state) => state.status,
  getRecommendedMaterials: (state) => state.recommendedMaterials,
};
const mutations = {
  changeStatus: (state, status) => {
    state.status = status;
  },
  setRecommendedMaterials: (state, recommendedMaterials) => {
    state.recommendedMaterials = recommendedMaterials;
  },
};
const actions = {
  fetchRecommendedMaterials: async ({ commit, rootGetters }, payload) => {
    try {
      commit('setRecommendedMaterials', []);
      commit('changeStatus', 'loading');
      const response = await request(
        'GET',
        'api/recommended-materials',
        rootGetters['auth/getToken'],
        {},
        payload.params,
      );
      setTimeout(commit('setRecommendedMaterials', response.data.slice(0, 4)), 250);
      commit('changeStatus', 'success');
      return Promise.resolve(response);
    } catch (e) {
      console.log(e);
      commit('changeStatus', 'error');
      return Promise.reject(e);
    }
  },
  notifyRecommendationDisplayed: async ({ rootGetters }) => {
    try {
      const response = await request(
        'GET',
        'api/recommendation-displayed',
        rootGetters['auth/getToken'],
        {},
        {},
      );
      return Promise.resolve(response);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
};
const state = {
  recommendedMaterials: {},
  status: '',
};

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state,
};
