import Vue from 'vue';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import VueVirtualScroller from 'vue-virtual-scroller';
import VueSocketIO from 'vue-socket.io';
import SocketIO from 'socket.io-client';
import GmapVue from 'gmap-vue';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import router from './router';
import store from './store';
import './shared/filters/filters';

import demoData from '../demo.json';
import './registerServiceWorker';
import translateCategory from './mixins/translateCategory';
import transformLangCode from './mixins/transformLangCode';
import i18n from './plugins/i18n';

Vue.mixin(translateCategory);
Vue.mixin(transformLangCode);

Vue.use(GmapVue, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: 'places',
  },
  autoBindAllEvents: false,
  installComponents: true,
  dynamicLoad: false,
});

Vue.config.productionTip = false;
Vue.use(VueTelInputVuetify, {
  vuetify,
});

Vue.use(VueVirtualScroller);

const isDemoApp = store.getters['demo/isDemoApp'];

if (isDemoApp) {
  localStorage.setItem('user-token', JSON.stringify(demoData.userToken));
  localStorage.setItem('user-company', JSON.stringify(demoData.userCompany));
} else {
  const socket = SocketIO(process.env.VUE_APP_API_URL, {
    autoConnect: false,
  });

  Vue.use(
    new VueSocketIO({
      connection: socket,
    }),
  );
}
const scrollPositions = {};

router.beforeEach((to, from, next) => {
  if (from.name) {
    scrollPositions[from.name] = window.scrollY;
  }
  document.title = to.meta.title || i18n.t('default_page_title');
  next();
});

router.afterEach((to) => {
  if (scrollPositions[to.name] !== undefined) {
    Vue.nextTick(() => {
      window.scrollTo(0, scrollPositions[to.name]);
    });
  } else {
    window.scrollTo(0, 0);
  }
});

i18n.locale = store.getters['locale/currentLanguage'];

store.dispatch('auth/initializeState').then(() => {
  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
  }).$mount('#app');
});
