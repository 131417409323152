import request from '@/utils/requests';

const getters = {
  getCompanies: (state) => state.companies,
  isFetchingCompanies: (state) => state.isFetchingCompanies,
};

const mutations = {
  SET_COMPANIES: (state, companies) => {
    state.companies = companies;
  },

  SET_IS_FETCHING_COMPANIES: (state, isFetchingCompanies) => {
    state.isFetchingCompanies = isFetchingCompanies;
  },
};

const actions = {
  fetchCompanies: async ({ rootGetters, commit }) => {
    let promiseResult;

    try {
      commit('SET_IS_FETCHING_COMPANIES', true);
      const response = await request(
        'GET',
        'admin/companies',
        rootGetters['auth/getToken'],
      );
      commit('SET_COMPANIES', response.data.items);
      promiseResult = Promise.resolve();
    } catch (e) {
      promiseResult = Promise.reject(e);
    } finally {
      commit('SET_IS_FETCHING_COMPANIES', false);
    }

    return promiseResult;
  },

  updateCompany: async ({ rootGetters }, { companyId, body }) => {
    try {
      const response = await request(
        'PUT',
        `admin/companies/${companyId}`,
        rootGetters['auth/getToken'],
        body,
      );
      return Promise.resolve(response.data);
    } catch (e) {
      return Promise.reject(e);
    }
  },
};

const state = {
  companies: [],
  isFetchingCompanies: false,
};

const modules = {};

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  modules,
  state,
};
