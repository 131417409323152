import request from '@/utils/requests';
import { cloneDeep } from 'lodash';

const getters = {
  getNotifications: (state) => cloneDeep(state.notifications),
  getUnseenNotificationsNumber: (state) => state.unseen,
};
const mutations = {
  setNotifications: (state, notifications) => {
    state.notifications = notifications;
  },
  addNotificationsToState: (state, notifications) => {
    if (!Array.isArray(notifications)) {
      state.notifications.total = notifications.total;
      state.notifications.totalPages = notifications.totalPages;
      state.notifications.currentPage = notifications.currentPage;
      state.notifications.items.push(...notifications.items);
    } else {
      const { length } = notifications;
      state.notifications.total += length;
      state.unseen += length;
      state.notifications.items = [...notifications, ...state.notifications.items];
    }
  },
  updateStateNotifications: (state, notifications) => {
    const notificationsMap = new Map();
    notifications.forEach((n) => {
      notificationsMap.set(n.id, n);
      if (n.seen) {
        state.unseen -= 1;
      } else {
        state.unseen += 1;
      }
    });
    state.notifications.items.forEach((n, index) => {
      if (notificationsMap.has(n.id)) {
        const updatedNotification = notificationsMap.get(n.id);

        // This doesn't update getter :(
        // state.notifications.items[index] = {
        //   ...state.notifications.items[index],
        //   ...updatedNotification,
        // };

        // This has O(n) complexity at worst :'(
        state.notifications.items.splice(index, 1, updatedNotification);
      }
    });
  },
  removeNotificationFromState: (state, notificationId) => {
    const idx = state.notifications.items.findIndex((n) => n.id === notificationId);
    const deletedNotification = state.notifications.items.splice(idx, 1)[0];
    if (!deletedNotification.seen) {
      state.unseen -= 1;
    }
    state.notifications.total -= 1;
  },
  updateUnseenState: (state, unseen) => {
    state.unseen = unseen;
  },
};
const actions = {
  fetchNotifications: async ({ commit, rootGetters }, args) => {
    try {
      const response = await request(
        'GET',
        'api/notifications',
        rootGetters['auth/getToken'],
        {},
        args.query,
      );
      if (args.replace) {
        commit('setNotifications', response.data);
      } else {
        commit('addNotificationsToState', response.data);
      }
      return Promise.resolve(response);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  fetchUnseenNotificationsCount: async ({ commit, rootGetters }) => {
    try {
      const response = await request(
        'GET',
        'api/notifications/unseen',
        rootGetters['auth/getToken'],
        {},
        {},
      );
      commit('updateUnseenState', response.data.unseen);
      return Promise.resolve(response.data);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  readNotifications: async ({ commit, rootGetters }, notifications) => {
    try {
      const response = await request(
        'PUT',
        'api/notifications/read',
        rootGetters['auth/getToken'],
        { notifications },
        {},
      );
      commit('updateStateNotifications', notifications);
      return Promise.resolve(response);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  deleteNotification: async ({ commit, rootGetters }, notificationId) => {
    try {
      const response = await request(
        'DELETE',
        `api/notifications/${notificationId}`,
        rootGetters['auth/getToken'],
        {},
        {},
      );
      commit('removeNotificationFromState', notificationId);
      return Promise.resolve(response);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  addNotificationsToState: ({ commit }, notifications) => {
    commit('addNotificationsToState', notifications);
  },
};
const modules = {};
const state = {
  notifications: {},
  unseen: 0,
};

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  modules,
  state,
};
