import { isEmpty } from 'lodash';
import demoData from '../../demo.json';
import {
  handleEndSynergy,
  handleMaterialsFiltering,
  handleNewMaterial,
  handleNewReview,
  handleNewUser,
} from './demo_helpers';

// eslint-disable-next-line no-unused-vars
const demoRequest = (method, endpoint, token, payload, queryParams) => {
  if (method === 'POST') {
    switch (endpoint) {
      case 'api/materials':
        handleNewMaterial(payload);
        break;
      case 'api/users/':
        handleNewUser(payload);
        break;
      case `api/exchanges/${payload?.exchange?.id}/feedback`:
        handleNewReview(payload);
        break;
      default:
        break;
    }
  } else if (method === 'PUT') {
    switch (endpoint) {
      case `api/exchanges/${payload.id}`:
        handleEndSynergy(payload);
        break;
      default:
        break;
    }
  } else if (method === 'GET') {
    switch (endpoint) {
      case 'api/materials':
        if ('company_id' in queryParams) {
          return { data: demoData[`api/materials?company_id=${queryParams.company_id}`] };
        }
        if (!isEmpty(queryParams.frequency) || 'map' in queryParams || 'page' in queryParams) {
          return handleMaterialsFiltering(
            queryParams.frequency,
            queryParams.map,
            queryParams?.geoloc,
            queryParams?.page,
          );
        }
        return { data: demoData['api/materials'] };
      default:
        break;
    }
  }
  return { data: demoData[endpoint] };
};

export default demoRequest;
