<template>
  <div>
    <v-navigation-drawer
      v-model="sidebar"
      app
      disable-resize-watcher
      class="hidden-md-and-up"
      color="#141919"
    >
      <div class="d-flex flex-grow-1 side-logo pa-2 elevation-2 nav-dark-bg">
        <v-img
          alt="The Surpluss Logo"
          contain
          :src="appLogo"
          transition="scale-transition"
        />
      </div>
      <v-list dense rounded class="align-center mt-2">
        <v-list-item
          v-for="item in smNavItems"
          :key="item.name"
          :to="item.route"
          active-class="sm-navigation-active"
          class="grey--text font-family-montserrat"
        >
          <v-list-item-icon>
            <v-icon
              color="grey"
              size="19"
              v-text="item.icon"
            />
          </v-list-item-icon>
          <v-list-item-content>
            {{ item.name }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      class="nav-dark-bg"
      dark
      height="90"
    >
      <v-container class="v-toolbar__content">
        <div class="d-flex flex-grow-1 align-center justify-center nav-bottom-border py-2">
          <v-app-bar-title class="hidden-md-and-up">
            <v-app-bar-nav-icon @click="sidebar = !sidebar"/>
          </v-app-bar-title>
          <router-link :to="{name: 'database'}">
            <v-img
              alt="The Surpluss Logo"
              contain
              :src="appLogo"
              transition="scale-transition"
              class="hidden-sm-and-down me-md-6 me-lg-16"
              width="100"
              v-if="!sidebar"
            />
          </router-link>
          <custom-nav-link routeName="/account/dashboard" :routeText="$t('navigation.dashboard')"/>
          <custom-nav-link routeName="/account/manager-panel" :routeText="$t('navigation.my_company')"/>
          <custom-nav-link routeName="/database" :routeText="$t('navigation.database')"/>
          <v-spacer></v-spacer>
          <custom-nav-button
              routeName="messages"
              :badgeEnabled="hasMessages"
              iconName="mdi-message-outline"
          />
          <notifications-menu/>
          <locale-selector />

          <v-btn
            text
            class="mx-3 mt-1"
            icon
            :to="{name: 'personal-info'}"
          >
            <v-avatar color="primary">
              <v-img v-if="getUserProfilePic() !== ''" :src="getUserProfilePic()"></v-img>
              <span v-else class="text-capitalize white--text">{{ getUserInitials() }}</span>
            </v-avatar>
          </v-btn>
          <v-hover v-slot="{ hover }" v-if="!isDemoApp">
            <v-btn
              icon
              @click="logout"
              class="mt-2"
              plain
            >
              <v-icon
                  :color="hover ? 'white' : 'grey'"
                  size="22"
              >
                mdi-logout
              </v-icon>
            </v-btn>
          </v-hover>
        </div>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
import appLogo from '@/assets/logo-dark.svg';
import { mapGetters } from 'vuex';
import { LOGOUT } from '@/store/modules/auth';
import CustomNavLink from '@/components/authenticated/navigation/CustomNavLink.vue';
import CustomNavButton from '@/components/authenticated/navigation/CustomNavButton.vue';
import NotificationsMenu from '@/components/authenticated/navigation/NotificationsMenu.vue';
import LocaleSelector from '@/components/navigation/LocaleSelector.vue';

export default {
  name: 'AuthenticatedNav',
  components: {
    NotificationsMenu,
    CustomNavButton,
    CustomNavLink,
    LocaleSelector,
  },
  data() {
    return {
      appLogo,
      sidebar: false,
      hasNotifications: false,
      hasMessages: false,
      smNavItems: [
        {
          name: this.$t('navigation.dashboard'),
          route: '/account/dashboard',
          text: true,
          icon: 'mdi-package',
        },
        {
          name: this.$t('navigation.my_company'),
          route: '/account/manager-panel',
          text: true,
          icon: 'mdi-domain',
        },
        {
          name: this.$t('navigation.database'),
          route: '/database',
          text: true,
          icon: 'mdi-database-outline',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      getUser: 'auth/getUser',
      isDemoApp: 'demo/isDemoApp',
    }),
  },
  sockets: {
    message() {
      this.$socket.emit('newMessagesNumber');
    },
    messageSeen() {
      this.$socket.emit('newMessagesNumber');
    },
    newOffer() {
      this.$socket.emit('listNotifications');
    },
    newMessagesNumber(data) {
      const number = data.count;
      this.hasMessages = number > 0;
    },
    pendingOffersNumber(data) {
      const number = data.count;
      this.hasNotifications = number > 0;
    },
  },
  mounted() {
    if (!this.isDemoApp) {
      this.$socket.emit('newMessagesNumber');
    }
  },
  methods: {
    getUserProfilePic() {
      if (this.getUser.image_url) {
        return this.getUser.image_url;
      }
      return '';
    },
    logout() {
      this.$store.dispatch(`auth/${LOGOUT}`);
      this.$socket.disconnect();
      this.$router.push('/login');
    },
    getUserInitials() {
      return `${this.getUser.first_name[0]}${this.getUser.last_name[0]}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.side-logo {
  background-color: #f5f5f5;
  height: 64px;
}
.nav-bottom-border {
  border-bottom: 0.5px solid #585B5B;
}
.sm-navigation-active {
  color: white !important;
}
</style>
