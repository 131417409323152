<template>
  <div>
    <v-navigation-drawer
      v-model="sidebar"
      app
      disable-resize-watcher
    >
      <v-list-item>
        <v-list-item-title class="text-h6">
          {{ $t("navigation.menu") }}
        </v-list-item-title>
        <v-btn
          icon
          @click="sidebar = false"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>
      <v-list dense rounded>
        <v-list-item
          v-for="item in cta"
          :key="item.name"
          :to="item.route">
          <v-list-item-content>{{ item.name }}</v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      app
      :color="isItLanding ? navBarBg : ''"
      :elevate-on-scroll="isItLanding"
      elevation="2"
    >
      <span class="hidden-sm-and-up">
          <v-app-bar-nav-icon @click="sidebar = !sidebar"/>
      </span>
      <v-container>
        <v-row>
          <v-toolbar-title>
            <router-link to="/">
              <v-img
                alt="The Surpluss Logo"
                contain
                :src="isItLanding ? logo : appLogo"
                transition="scale-transition"
                width="100"
              />
            </router-link>
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            v-for="item in cta"
            :key="item.name"
            :to="item.route"
            :text="item.text"
            :outlined="item.outline"
            :class="item.class"
          >
            {{ item.name }}
          </v-btn>
          <div class="mt-n2">
            <locale-selector />
          </div>
        </v-row>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
import appLogo from '@/assets/logo.svg';
import appLogoDark from '@/assets/logo-dark.svg';
import { mapGetters } from 'vuex';
import LocaleSelector from './LocaleSelector.vue';

export default {
  name: 'NavBar',
  data() {
    return {
      appLogo,
      appLogoDark,
      logo: appLogo,
      navBarBg: 'transparent',
      sidebar: false,
      cta: [
        {
          name: this.$t('components.navigation.help'),
          route: '/help',
          text: true,
          outline: false,
          class: 'mx-3 hidden-xs-only',
        },
        {
          name: this.$t('components.navigation.login'),
          route: '/login',
          text: false,
          outline: true,
          class: 'mx-3 hidden-xs-only',
        },
        {
          name: this.$t('components.navigation.register'),
          route: '/register',
          text: false,
          outline: false,
          class: 'mx-3 hidden-xs-only primary',
        },
      ],
      navMenuItems: [
        { name: this.$t('components.navigation.home'), route: '/' },
        { name: this.$t('components.navigation.marketplace'), route: '/marketplace' },
      ],
    };
  },
  components: {
    LocaleSelector,
  },
  computed: {
    isItLanding() {
      return this.$route.name === 'landing';
    },
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
    }),
  },
  mounted() {
    window.onscroll = () => {
      this.changeColor();
    };
    this.logo = this.isItLanding ? this.appLogoDark : this.appLogo;
  },
  methods: {
    changeColor() {
      if (
        document.body.scrollTop > 100
        || document.documentElement.scrollTop > 100
      ) {
        this.navBarBg = 'white';
        if (this.isItLanding) {
          this.logo = appLogo;
        }
      } else {
        this.navBarBg = 'transparent';
        if (this.isItLanding) {
          this.logo = appLogoDark;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.register-btn{
  background-color: $srpls-primary!important;
}
</style>
