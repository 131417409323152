import request from '@/utils/requests';
import reviews from '@/store/modules/company/reviews';
import editCompany from '@/store/modules/company/editCompany';

const getters = {
  getCompany: (state) => state.company,
  getStatus: (state) => state.status,
  getCompanyUsers: (state) => state.company.users,
  getCompanyStats: (state) => state.companyStats,
  getSdgExchanges: (state) => state.sdgExchanges,
};

const mutations = {
  setStatus: (state, status) => {
    state.status = status;
  },
  setCompany: (state, company) => {
    state.company = company;
  },
  setCompanyStats: (state, companyStats) => {
    state.companyStats = companyStats;
    const missingMonths = 4 - companyStats.length;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < missingMonths; i++) {
      state.companyStats.push({
        kgs: 0,
        hours: 0,
        completed_synergies: 0,
        money_saved: 0,
        revenue_made: 0,
        kgs_change_percent: 0,
        hours_change_percent: 0,
        completed_synergies_change_percent: 0,
        money_saved_change_percent: 0,
        revenue_made_change_percent: 0,
      });
    }
  },
  setSdgExchanges: (state, sdgExchanges) => {
    state.sdgExchanges = sdgExchanges;
  },
};

const actions = {
  fetchCompanyById: async ({ commit, rootGetters }, company) => {
    try {
      commit('setCompany', {});
      commit('setStatus', 'loading');
      const response = await request(
        'GET',
        `api/companies/${company.id}`,
        rootGetters['auth/getToken'],
        {},
        {},
      );
      setTimeout(commit('setCompany', response.data), 250);
      commit('setStatus', 'success');

      return Promise.resolve(response.data);
    } catch (e) {
      console.log(e);
      commit('setStatus', 'error');
      return Promise.reject(e);
    }
  },

  fetchCompanyStats: async ({ commit, rootGetters }, company) => {
    try {
      commit('setStatus', 'loading');
      const response = await request(
        'GET',
        `api/companies/${company.id}/stats`,
        rootGetters['auth/getToken'],
        {},
        {},
      );
      commit('setCompanyStats', response.data);
      commit('setStatus', 'success');

      return Promise.resolve(response.data);
    } catch (e) {
      console.log(e);
      commit('setStatus', 'error');
      return Promise.reject(e);
    }
  },

  updateCompany: async ({ rootGetters }, payload) => {
    console.log(payload);
    try {
      const response = await request(
        'PUT',
        `api/companies/${payload.company.id}`,
        rootGetters['auth/getToken'],
        payload.company,
        {},
      );

      return Promise.resolve(response);
    } catch (e) {
      return Promise.reject(e);
    }
  },

  fetchSdgSynergies: async ({ rootGetters, commit }, companyId) => {
    try {
      const { data } = await request(
        'GET',
        `api/companies/${companyId}/sdg-exchanges`,
        rootGetters['auth/getToken'],
        {},
      );
      commit('setSdgExchanges', data);
      return Promise.resolve(data);
    } catch (e) {
      return Promise.reject(e);
    }
  },
};

const modules = {
  reviews,
  edit: editCompany,
};

const state = {
  company: {},
  companyStats: [
    {
      kgs: 0,
      hours: 0,
      completed_synergies: 0,
      money_saved: 0,
      revenue_made: 0,
      kgs_change_percent: 0,
      hours_change_percent: 0,
      completed_synergies_change_percent: 0,
      money_saved_change_percent: 0,
      revenue_made_change_percent: 0,
    },
    {
      kgs: 0,
      hours: 0,
      completed_synergies: 0,
      money_saved: 0,
      revenue_made: 0,
      kgs_change_percent: 0,
      hours_change_percent: 0,
      completed_synergies_change_percent: 0,
      money_saved_change_percent: 0,
      revenue_made_change_percent: 0,
    },
    {
      kgs: 0,
      hours: 0,
      completed_synergies: 0,
      money_saved: 0,
      revenue_made: 0,
      kgs_change_percent: 0,
      hours_change_percent: 0,
      completed_synergies_change_percent: 0,
      money_saved_change_percent: 0,
      revenue_made_change_percent: 0,
    },
    {
      kgs: 0,
      hours: 0,
      completed_synergies: 0,
      money_saved: 0,
      revenue_made: 0,
      kgs_change_percent: 0,
      hours_change_percent: 0,
      completed_synergies_change_percent: 0,
      money_saved_change_percent: 0,
      revenue_made_change_percent: 0,
    },
  ],
  status: '',
  sdgExchanges: [],
};

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  modules,
  state,
};
