import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

import en from 'vuetify/lib/locale/en';
import da from 'vuetify/lib/locale/da';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { en, da },
    current: 'en',
  },
  theme: {
    themes: {
      light: {
        primary: '#23BABD',
        secondary: '#4E5BA6',
        error: '#F04438',
        success: '#12B76A',
        warning: '#F79009',
        tertiaryBlue: '#0B3954',
        tertiaryRed: '#FF6663',
        tertiaryPurple: '#645E9D',
        colors,
      },
    },
  },
});
