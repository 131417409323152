<template>
  <v-footer padless>
    <div id="footer-s1">
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <p class="white--text">
              <span class="font-weight-bold">
                {{ $t('components.footer.newsletter') }}
              </span>
              <br>
              <span class="font-weight-light">
                {{ $t('components.footer.newsletter_description') }}
              </span>
            </p>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="3">
            <v-text-field solo dense :label="$t('forms.fields.email.label')"></v-text-field>
          </v-col>
          <v-col cols="12" md="auto">
            <v-btn>{{ $t('components.footer.subscribe') }}</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- <div id="footer-s2">
      <v-container>
        <v-row>
          <v-col cols="12" md="6" lg="4">
            <v-img src="@/assets/logo-dark.svg" width="20%" />
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="auto">
            <v-row class="justify-end">
              <v-col cols="12" md="auto">
                <router-link to="#" class="text-decoration-none footer-link-hover">
                  {{ $t('components.footer.partners') }}
                </router-link>
              </v-col>
              <v-col cols="12" md="auto">
                <router-link to="#" class="text-decoration-none footer-link-hover">
                  {{ $t('components.footer.company') }}
                </router-link>
              </v-col>
              <v-col cols="12" md="auto">
                <router-link to="#" class="text-decoration-none footer-link-hover">
                  {{ $t('components.footer.help_desk') }}
                </router-link>
              </v-col>
              <v-col cols="12" md="auto">
                <router-link to="#" class="text-decoration-none footer-link-hover">
                  {{ $t('components.footer.resources') }}
                </router-link>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div> -->
    <div id="footer-s3">
      <v-container>
        <div class="d-flex align-center flex-wrap">
          <div v-for="(social, n) in socialButtons" :key="n">
            <v-btn icon :href="social.url" target="_blank">
              <v-icon>{{ social.icon }}</v-icon>
            </v-btn>
          </div>

          <div class="mx-auto ms-md-auto me-md-0">
            {{ $t('authentication.login.footer.trademark_text') }}
          </div>
        </div>

      </v-container>
    </div>
  </v-footer>
</template>

<script>
export default {
  name: 'AppFooter',
  data: () => ({
    socialButtons: [
      { icon: 'mdi-facebook', url: 'https://www.facebook.com/thesurpluss' },
      { icon: 'mdi-instagram', url: 'https://www.instagram.com/the_surpluss/' },
      { icon: 'mdi-linkedin', url: 'https://www.linkedin.com/company/the-surpluss' },
    ],
  }),
};
</script>

<style lang="scss" scoped>
#footer-s1 {
  background: #101828;
  width: 100%;
}

#footer-s2 {
  background: #1D2939;
  width: 100%;
}

#footer-s3 {
  background: #E1F9F9;
  width: 100%;
}

.footer-link-hover {
  color: #bdbdbd;

  &:hover {
    color: white;
  }
}
</style>
