import request from '@/utils/requests';

const getters = {
  getOffers: (state) => state.offers,
  getOffer: (state) => state.offer,
  getPendingOffers: (state) => state.pendingOffers,
};
const mutations = {
  setOffers: (state, offers) => {
    state.offers = offers;
  },
  setOffer: (state, offer) => {
    state.offer = offer;
  },
  setPendingOffers: (state, pendingOffers) => {
    state.pendingOffers = pendingOffers;
  },
};
const actions = {
  fetchOffers: async ({ commit, rootGetters }, payload) => {
    try {
      const response = await request(
        'GET',
        'api/offers',
        rootGetters['auth/getToken'],
        {},
        payload.params,
      );
      commit('setOffers', response.data);
      return Promise.resolve(response);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  fetchPendingOffers: async ({ commit, rootGetters }, payload) => {
    try {
      const response = await request(
        'GET',
        'api/offers/pending',
        rootGetters['auth/getToken'],
        {},
        payload.params,
      );
      commit('setPendingOffers', response.data);
      return Promise.resolve();
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  fetchOfferById: async ({ commit, rootGetters }, payload) => {
    try {
      const response = await request(
        'GET',
        `api/offers/${payload.offer.id}`,
        rootGetters['auth/getToken'],
        {},
        {},
      );
      commit('setOffer', response.data);
      return Promise.resolve(response);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  createOffer: async ({ commit, rootGetters }, payload) => {
    try {
      const response = await request(
        'POST',
        'api/offers',
        rootGetters['auth/getToken'],
        payload.offer,
        {},
      );
      commit('setOffer', response.data);
      return Promise.resolve(response);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  updateOffer: async ({ commit, rootGetters }, payload) => {
    try {
      const response = await request(
        'PUT',
        'api/offers',
        rootGetters['auth/getToken'],
        payload.material,
        {},
      );
      commit('setOffer', response.data);
      return Promise.resolve(response);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  reviewOffer: async ({ rootGetters }, payload) => {
    try {
      const response = await request(
        'POST',
        'api/offers/review',
        rootGetters['auth/getToken'],
        payload.reviewOffer,
        {},
      );
      return Promise.resolve(response);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  deleteOffer: async ({ rootGetters }, payload) => {
    try {
      const response = await request(
        'DELETE',
        `api/offers/${payload.offer.id}`,
        rootGetters['auth/getToken'],
        {},
        {},
      );
      return Promise.resolve(response);
    } catch (e) {
      return Promise.reject(e);
    }
  },
};

const modules = {};
const state = {
  offers: {},
  offer: {},
  pendingOffers: {},
};

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  modules,
  state,
};
