import axios from 'axios';
import request from '@/utils/requests';

const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
const SIGNUP_ERROR = 'SIGNUP_ERROR';

const getters = {
  getCountries: (state) => state.countries,
  getCurrencies: (state) => state.currencies,
};

const mutations = {
  [SIGNUP_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [SIGNUP_SUCCESS]: (state) => {
    state.status = 'success';
  },
  [SIGNUP_ERROR]: (state) => {
    state.status = 'error';
  },
  setCountriesAndCurrencies: (state, countries) => {
    state.countries = [];
    state.currencies = [];
    countries.forEach((country) => {
      state.countries.push(country.code);
      state.currencies.push({
        currencyCode: country.currency_code,
        currencySymbol: country.currency_symbol,
      });
    });
  },
};

const actions = {
  [SIGNUP_REQUEST]: async ({ commit }, company) => {
    try {
      commit(SIGNUP_REQUEST);
      const registerOptions = {
        method: 'POST',
        url: `${process.env.VUE_APP_API_URL}api/auth/register`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        data: { ...company },
      };

      const response = await axios(registerOptions);
      commit(SIGNUP_SUCCESS);
      return Promise.resolve(response);
    } catch (e) {
      commit(SIGNUP_ERROR);
      return Promise.reject(e);
    }
  },

  fetchCountriesAndCurrencies: async ({ commit }) => {
    try {
      const response = await request(
        'GET',
        'api/common/countries',
        null,
        {},
        { country_codes: ['GR', 'DE', 'DK', 'AE', 'GB', 'IT'] },
      );
      commit('setCountriesAndCurrencies', response.data);
      return Promise.resolve();
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
};

const modules = {};

const state = {
  status: '',
  countries: [],
  currencies: [],
};

export {
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
};

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  modules,
  state,
};
