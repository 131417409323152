<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on" class="mt-1">
        <v-icon>{{ currentFlag }}</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item @click="switchLanguage('en')">
        <v-list-item-action>
          <span class="fi fi-gb"></span>
        </v-list-item-action>
        <v-list-item-content>English</v-list-item-content>
      </v-list-item>

      <v-list-item @click="switchLanguage('dk')">
        <v-list-item-action>
          <span class="fi fi-dk"></span>
        </v-list-item-action>
        <v-list-item-content>Dansk</v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'LocaleSelector',
  computed: {
    ...mapGetters('locale', ['currentLanguage']),
    currentFlag() {
      const locale = this.$i18n.locale === 'en' ? 'gb' : this.$i18n.locale;

      return `fi fi-${locale}`;
    },
  },
  methods: {
    ...mapActions('locale', ['setLanguage']),
    switchLanguage(lang) {
      this.setLanguage(lang);
      this.$forceUpdate();
      // this.$i18n.locale = lang;
    },
  },
};
</script>

<style>
@import 'flag-icons/css/flag-icons.min.css';
</style>
