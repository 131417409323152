import request from '@/utils/requests';

const getters = {
  isDeleteMode: (state) => state.isDeleteMode,
  getUserToDelete: (state) => state.userToDelete,
};

const mutations = {
  SET_IS_DELETE_MODE: (state, isDeleteMode) => {
    state.isDeleteMode = isDeleteMode;
  },

  SET_USER_TO_DELETE: (state, userToDelete) => {
    state.userToDelete = userToDelete;
  },
};

const actions = {
  startDeleteMode: ({ commit }) => {
    commit('SET_IS_DELETE_MODE', true);
  },

  setUserToDelete: ({ commit }, userToDelete) => {
    commit('SET_USER_TO_DELETE', userToDelete);
  },

  // eslint-disable-next-line no-shadow
  deleteUser: async ({ getters, rootGetters, dispatch }) => {
    // eslint-disable-next-line dot-notation
    const user = getters['getUserToDelete'];

    if (!user) {
      return;
    }

    const { id } = user;
    try {
      await request(
        'DELETE',
        `api/users/${id}`,
        rootGetters['auth/getToken'],
      );
      dispatch('users/setShouldRefetchUsers', true, { root: true });
      dispatch('endDeleteMode');
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  },

  endDeleteMode: ({ commit }) => {
    commit('SET_IS_DELETE_MODE', false);
    commit('SET_USER_TO_DELETE', null);
  },
};

const modules = {};

const state = {
  isDeleteMode: false,
  userToDelete: null,
};

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  modules,
  state,
};
