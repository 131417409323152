import request from '@/utils/requests';
import editMaterials from '@/store/modules/material/editMaterials';

const getters = {
  fetchStatus: (state) => state.status,
  getMaterials: (state) => state.materials,
  getFetchedMaterial: (state) => state.fetchedMaterial,
  getShouldRefetchMaterials: (state) => state.shouldRefetchMaterials,
};
const mutations = {
  changeStatus: (state, status) => {
    state.status = status;
  },
  setMaterials: (state, materials) => {
    state.materials = materials;
  },
  setFetchedMaterial: (state, material) => {
    state.fetchedMaterial = material;
  },
  setShouldRefetchMaterials: (state, shouldRefetchMaterials) => {
    state.shouldRefetchMaterials = shouldRefetchMaterials;
  },
};
const actions = {
  fetchMaterials: async ({ commit, rootGetters }, payload) => {
    commit('setShouldRefetchMaterials', false);
    try {
      commit('setMaterials', []);
      commit('changeStatus', 'loading');
      const response = await request(
        'GET',
        'api/materials',
        rootGetters['auth/getToken'],
        {},
        payload.params,
      );
      setTimeout(commit('setMaterials', response.data), 250);
      commit('changeStatus', 'success');
      return Promise.resolve(response);
    } catch (e) {
      console.log(e);
      commit('changeStatus', 'error');
      return Promise.reject(e);
    }
  },
  fetchMaterialById: async ({ commit, rootGetters }, material) => {
    try {
      commit('setMaterials', {});
      commit('changeStatus', 'loading');
      const response = await request(
        'GET',
        `api/materials/${material.id}`,
        rootGetters['auth/getToken'],
        {},
        {},
      );
      setTimeout(commit('setFetchedMaterial', response.data), 250);
      commit('changeStatus', 'success');
      return Promise.resolve(response);
    } catch (e) {
      console.log(e);
      commit('changeStatus', 'error');
      return Promise.reject(e);
    }
  },
  fetchMaterialQuery: async ({ commit, rootGetters }, query) => {
    commit('setShouldRefetchMaterials', false);
    try {
      commit('setMaterials', {});
      commit('changeStatus', 'loading');
      const response = await request(
        'GET',
        'api/materials',
        rootGetters['auth/getToken'],
        {},
        query,
      );
      setTimeout(commit('setMaterials', response.data), 250);
      commit('changeStatus', 'success');
      return Promise.resolve(response);
    } catch (e) {
      console.log(e);
      commit('changeStatus', 'error');
      return Promise.reject(e);
    }
  },
  createMaterial: async ({ commit, rootGetters }, payload) => {
    try {
      commit('changeStatus', 'loading');
      const response = await request(
        'POST',
        'api/materials',
        rootGetters['auth/getToken'],
        payload.offering,
        payload.query,
      );
      commit('changeStatus', 'success');
      return Promise.resolve(response);
    } catch (e) {
      console.log(e.message);
      commit('changeStatus', 'error');
      return Promise.reject(e);
    }
  },
  updateMaterial: async ({ commit, rootGetters }, payload) => {
    try {
      const response = await request(
        'PUT',
        `api/materials/${payload.material.id}`,
        rootGetters['auth/getToken'],
        payload.material,
        payload.params,
      );
      commit('setShouldRefetchMaterials', true);
      return Promise.resolve(response);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  setShouldRefetchMaterials: ({ commit }, shouldRefetchMaterials) => {
    commit('setShouldRefetchMaterials', shouldRefetchMaterials);
  },
};
const modules = { edit: editMaterials };
const state = {
  materials: {},
  fetchedMaterial: {},
  status: '',
  shouldRefetchMaterials: false,
};

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  modules,
  state,
};
