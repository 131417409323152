const getters = {
  isDemoApp: (state) => state.isDemoApp,
};
const mutations = {};
const actions = {};
const modules = {};
const state = {
  isDemoApp: process.env.VUE_APP_DEMO_MODE,
};

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  modules,
  state,
};
