<template>
  <v-menu
    offset-y
    transition="slide-y-transition"
    bottom
    left
    content-class="rounded-xl font-family-montserrat"
    v-model="notificationsDropdownOpened"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          icon
          plain
          class="mr-4 mt-2"
          v-bind="attrs"
          v-on="on"
      >
        <v-badge
            :value="badgeEnabled"
            bordered
            dot
            overlap
            color="error"
        >
          <v-hover v-slot="{ hover }">
            <v-icon
                :color="hover ? 'white' : 'grey'"
                size="22"
            >mdi-bell-outline</v-icon>
          </v-hover>
        </v-badge>
      </v-btn>
    </template>
    <div
      style="background-color: white"
      class="d-flex flex-row flex-grow-1 pt-6 px-6 align-center"
    >
      <div class="text-h6 font-weight-bold ">{{ $t('general.notifications') }}</div>
      <v-spacer></v-spacer>
      <router-link v-if="false" to="/notifications">{{ $t('general.view_all') }}</router-link>
    </div>
    <v-list
      :style="`height: 55vh; width: ${notificationsWidth}vw; max-width: 410px; max-height: 635px`"
      class="notificationsList pa-3"
      @scroll.native="handleScroll"
    >
      <v-list-item
        v-for="(n, idx) in getNotifications?.items"
        :key="idx"
        class="notificationItem"
        :to="decideNotificationPath(n.type)"
        @click="handleNotificationClick(n)"
      >
        <div class="d-flex flex-row flex-grow-1 align-start flex-gap-2 py-5">
          <div class="statusIconWrapper">
            <v-icon
              v-if="!n?.seen"
              color="#FFB3B1"
              class="statusIcon"
            >
              mdi-circle
            </v-icon>
          </div>
          <div class="d-flex flex-grow-1 flex-column flex-gap-1">
            <div>{{n.message}}</div>
            <div
              class="text-subtitle-2 font-weight-regular"
              :style="{ color: '#FFB3B1' }"
            >
              {{calculateTimeAgo(n.created_at)}}
            </div>
          </div>
          <v-menu offset-y left rounded="xl">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="#A2A9B8"
                v-on="on"
                v-bind="attrs"
                @click.stop.prevent
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list class="px-2">
              <v-list-item class="menuItem rounded-xl" @click="readNotifications([n])">
                <div class="d-flex flex-row flex-grow-1 align-center flex-gap-1" v-if="!n.seen" >
                  <v-icon>mdi-check</v-icon>
                  <div>{{ $t('general.mark_read') }}</div>
                </div>
                <div class="d-flex flex-row flex-grow-1 align-center flex-gap-1" v-else>
                  <v-icon>mdi-check</v-icon>
                  <div>{{ $t('general.mark_unread') }}</div>
                </div>
              </v-list-item>
              <v-list-item class="menuItem rounded-xl" @click="removeNotification(n)">
                <div class="d-flex flex-row flex-grow-1 align-center flex-gap-1">
                  <v-icon>mdi-trash-can-outline</v-icon>
                  <div>{{ $t('general.remove') }}</div>
                </div>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-list-item>
      <v-list-item>
        <div
          v-if="fetchingNotifications || hasMoreItems"
          class="d-flex flex-column flex-grow-1 align-center justify-center px-10"
        >
            <div>{{ $t('components.navigation.loading_notifications') }}</div>
            <v-progress-linear
              color="black"
              indeterminate
              rounded
              height="3"
            ></v-progress-linear>
        </div>
        <div
          v-else
          class="d-flex flex-column flex-grow-1 align-center justify-center"
        >
          <small>{{ $t('components.navigation.viewing_all_notifications') }}</small>
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatDistanceToNow } from 'date-fns';
import { isEmpty } from 'lodash';

export default {
  name: 'NotificationsMenu',
  components: {},
  data() {
    return {
      fetchingNotifications: false,
      page: 0,
      size: 8,
      retainOverlay: false,
      notificationsDropdownOpened: false,
    };
  },
  async beforeMount() {
    await this.$store.dispatch('notifications/fetchUnseenNotificationsCount', {});
  },
  watch: {
    async notificationsDropdownOpened(newVal) {
      if (newVal && isEmpty(this.getNotifications.items)) {
        await this.loadNotifications();
      }
    },
  },
  computed: {
    notificationsWidth() {
      // eslint-disable-next-line no-nested-ternary
      return ['xs', 'sm'].includes(this.$vuetify.breakpoint.name) ? 60 : ['md', 'lg'].includes(this.$vuetify.breakpoint.name) ? 35 : 25;
    },
    ...mapGetters({
      getNotifications: 'notifications/getNotifications',
      getUnseenNotificationsCount: 'notifications/getUnseenNotificationsNumber',
    }),
    hasMoreItems() {
      return this.getNotifications?.totalPages > this.page + 1;
    },
    badgeEnabled() {
      return this.getUnseenNotificationsCount > 0;
    },
  },
  sockets: {
    newNotification(data) {
      this.$store.dispatch('notifications/addNotificationsToState', [data]);
    },
  },
  methods: {
    calculateTimeAgo(datetime) {
      return formatDistanceToNow(new Date(datetime));
    },
    decideNotificationPath(type) {
      switch (type) {
        case ('offers'): {
          return { name: 'pending-offers' };
        } case ('synergies'): {
          return { name: 'synergies' };
        } case ('new_materials'): {
          return { name: 'database' };
        } case ('dashboard'): {
          return { name: 'company-dashboard' };
        } default: {
          return { name: '' };
        }
      }
    },
    async handleScroll(e) {
      const { scrollTop, offsetHeight, scrollHeight } = e.target;
      if ((scrollTop + offsetHeight) >= scrollHeight) {
        await this.loadMoreNotifications();
      }
    },
    async handleNotificationClick(n) {
      if (!n.seen) {
        await this.readNotifications([n]);
      }
    },
    async loadMoreNotifications() {
      if (this.hasMoreItems && !this.fetchingNotifications) {
        this.page += 1;
        await this.loadNotifications(false);
      }
    },
    async loadNotifications(replace = true) {
      try {
        this.fetchingNotifications = true;
        const query = { page: this.page, size: this.size };
        await this.$store.dispatch('notifications/fetchNotifications', { query, replace });
      } catch (e) {
        this.$root.snackBar.show({
          appear: true,
          color: 'error',
          text: `${this.$t('components.navigation.notifications_fetch_error')}: ${e.message}`,
          timeout: 2000,
        });
      } finally {
        this.fetchingNotifications = false;
      }
    },
    async readNotifications(notifications) {
      const payload = [];
      notifications.forEach((n) => {
        const updatedNotification = { ...n, seen: !n.seen };
        payload.push(updatedNotification);
      });
      try {
        await this.$store.dispatch('notifications/readNotifications', payload);
      } catch (e) {
        this.$root.snackBar.show({
          appear: true,
          color: 'error',
          text: `${this.$t('components.navigation.notifications_update_error')}: ${e.message}`,
          timeout: 2000,
        });
      }
    },
    async removeNotification(notification) {
      try {
        await this.$store.dispatch('notifications/deleteNotification', notification.id);
      } catch (e) {
        this.$root.snackBar.show({
          appear: true,
          color: 'error',
          text: `${this.$t('components.navigation.notifications_delete_error')}: ${e.message}`,
          timeout: 2000,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.notificationsList {
  overflow-y: auto;
}
.notificationsList::-webkit-scrollbar {
  width: 13px;
}

.notificationsList::-webkit-scrollbar-track {
  border: none;
}

.notificationsList::-webkit-scrollbar-thumb {
  background: $srpls-bg-primary;
  border-radius: 7px;
}

.notificationsList::-webkit-scrollbar-thumb:hover {
  background: #C3D1F1D8;
}
.v-list-item--link::before {
  // makes sure list item backrgound is transparent
  // otherwise has a grey tint due to <a>
  background-color: transparent !important;
}
.notificationItem {
  border-radius: 24px !important;
  -webkit-transition: background-color 0.3s ease-out;
  -moz-transition: background-color 0.3s ease-out;
  -o-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out;
}
.notificationItem:hover {
  cursor: pointer;
  background: #E4EBF8 !important;
  border-radius: 24px !important;
}
.menuItem:hover {
  cursor: pointer;
  background-color: #E4EBF8;
}
.statusIconWrapper {
  width: 4vw;
  min-width: 18px;
  max-width: 20px;
}
.statusIcon {
  font-size: clamp(18px, 4vw, 20px);
}
</style>
