import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue'),
    children: [
      {
        path: 'database',
        name: 'database',
        component: () => import('@/views/MarketplaceView.vue'),
      },
      {
        path: 'material/:materialId',
        name: 'single-material',
        component: () => import('@/views/SingleMaterialView.vue'),
        props: true,
      },
      {
        path: 'messages',
        name: 'messages',
        component: () => import('@/views/ChatView.vue'),
      },
      {
        path: 'companies/:id',
        name: 'public-company',
        component: () => import('@/views/PublicCompanyView.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'users/:id',
        name: 'public-user',
        component: () => import('@/views/PublicUserView.vue'),
      },
      {
        path: 'account',
        name: 'account',
        component: () => import('@/views/AccountView.vue'),
        children: [
          {
            path: 'personal-info',
            name: 'personal-info',
            component: () => import('@/views/PersonalInfoView.vue'),
          },
          {
            path: 'manager-panel',
            name: 'manager-panel',
            component: () => import('@/views/ManagerPanelView.vue'),
          },
          {
            path: 'manager-panel',
            name: 'offerings',
            component: () => import('@/views/ManagerPanelView.vue'),
            props: { initialOption: 'offerings' },
          },
          {
            path: 'manager-panel',
            name: 'user-management',
            component: () => import('@/views/ManagerPanelView.vue'),
            props: { initialOption: 'user-management' },
          },
          {
            path: 'dashboard',
            name: 'company-dashboard',
            component: () => import('@/views/dashboard/CompanyDashboardView.vue'),
          },
          {
            path: 'pending-offers',
            name: 'pending-offers',
            component: () => import('@/views/dashboard/DashboardOffersView.vue'),
            props: { initialOption: 'pending-offers' },
          },
          {
            path: 'synergies',
            name: 'synergies',
            component: () => import('@/views/dashboard/DashboardOffersView.vue'),
            props: { initialOption: 'synergies' },
          },
        ],
      },
    ],
  },
];

const demoRouter = new VueRouter({
  mode: 'history',
  routes,
});

demoRouter.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    next('/database');
  } else {
    next();
  }
});

export default demoRouter;
