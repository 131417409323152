// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import request from '@/utils/requests';

const LOGIN_REQUEST = 'LOGIN_REQUEST';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_ERROR = 'LOGIN_ERROR';
const LOGOUT = 'LOGOUT';

const getters = {
  isAuthenticated: (state) => !!state.token && Date.now() <= state.expiresAt * 1000,
  authStatus: (state) => state.status,
  getToken: (state) => state.token,
  getUser: (state) => state.user,
  getUserCompanyPrimaryAddress: (state) => state.userCompany.addresses.find(
    (item) => item.primary,
  ),
  getUserRole: (state) => state.userRole,
  isAdmin: (state) => state.userRole === 'ADMIN',
  getEmail: (state) => state.email,
  getUserCompanyId: (state) => state.user.company_id,
  getUserCompany: (state) => state.userCompany,
  isFetchingUserCompany: (state) => state.isFetchingUserCompany,
};

const mutations = {
  [LOGIN_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [LOGIN_SUCCESS]: (state, resp) => {
    state.status = 'success';
    state.token = resp.token;
    const decodedToken = jwt_decode(state.token);
    state.expiresAt = decodedToken.exp;
    state.user = resp.user;
    state.userRole = resp.user.role;
  },
  [LOGIN_ERROR]: (state) => {
    state.status = 'error';
  },
  [LOGOUT]: (state) => {
    state.token = '';
    state.expiresAt = null;
    state.userRole = '';
    state.user = {};
  },
  setUserCompany: (state, userCompany) => {
    state.userCompany = userCompany;
  },
  setIsFetchingUserCompany: (state, isFetchingUserCompany) => {
    state.isFetchingUserCompany = isFetchingUserCompany;
  },
  initializeStore: (state) => {
    state.status = 'loading';

    if (localStorage.getItem('user-token')) {
      const userToken = JSON.parse(localStorage.getItem('user-token'));
      state.token = userToken.token;
      state.expiresAt = userToken.expiresAt;
      state.user = userToken.user;
      state.userRole = userToken.role;
    }

    if (localStorage.getItem('user-company')) {
      state.userCompany = JSON.parse(localStorage.getItem('user-company'));
    }

    state.status = 'success';
  },
  setEmail: (state) => {
    state.email = localStorage.getItem('email') || '';
  },
  setUser: (state, user) => {
    state.user = user;
  },
};

const actions = {
  [LOGIN_REQUEST]: async ({ commit, dispatch }, payload) => {
    try {
      commit(LOGIN_REQUEST);
      const response = await request(
        'POST',
        'api/auth/login',
        '',
        { username: payload.email, password: payload.password },
        {},
      );

      let decodedToken;
      if (response.data.token) {
        decodedToken = jwt_decode(response.data.token);
      }
      const userToken = {
        user: response.data.user,
        token: response.data.token,
        role: response.data.user.role,
        expiresAt: decodedToken.exp,
      };

      localStorage.setItem('user-token', JSON.stringify(userToken));
      commit(LOGIN_SUCCESS, response.data);

      if (payload.rememberMe) {
        localStorage.setItem('email', payload.email);
      }

      await dispatch('fetchUserCompany');

      return Promise.resolve(response);
    } catch (e) {
      commit(LOGIN_ERROR);
      localStorage.removeItem('user-token');
      return Promise.reject(e);
    }
  },
  [LOGOUT]: ({ commit }) => {
    localStorage.removeItem('user-token');
    localStorage.removeItem('user-company');
    commit(LOGOUT);
    return Promise.resolve();
  },

  fetchUserCompany: async ({ commit, rootGetters, dispatch }) => {
    let promiseResult;

    try {
      commit('setIsFetchingUserCompany', true);
      const companyId = rootGetters['auth/getUserCompanyId'];
      const company = await dispatch(
        'companies/fetchCompanyById',
        { id: companyId },
        { root: true },
      );
      commit('setUserCompany', company);
      localStorage.setItem('user-company', JSON.stringify(company));
      promiseResult = Promise.resolve();
    } catch (e) {
      console.log(e);
      promiseResult = Promise.reject(e);
    } finally {
      commit('setIsFetchingUserCompany', false);
    }

    return promiseResult;
  },

  initializeState: async ({ commit, dispatch }) => {
    const userToken = localStorage.getItem('user-token');
    if (userToken) {
      commit('initializeStore');
    } else {
      dispatch(LOGOUT);
    }
    commit('setEmail');
    return Promise.resolve();
  },
  setUser: async ({ commit, dispatch }, user) => {
    commit('setUser', user);
    let userToken = JSON.parse(localStorage.getItem('user-token'));
    userToken = { ...userToken, ...user };
    localStorage.setItem('user-token', JSON.stringify(userToken));
    await dispatch('fetchUserCompany');
  },
};

const modules = {};

const state = {
  token: '',
  expiresAt: null,
  user: {},
  userCompany: {},
  isFetchingUserCompany: false,
  userRole: '',
  status: '',
  email: '',
};

export {
  LOGIN_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_ERROR,
  LOGOUT,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules,
};
