import request from '@/utils/requests';

const getters = {
  getStoredMaterialGroups: (state) => state.storedMaterialGroups,
  getFetchStatus: (state) => state.fetchStatus,
};

const mutations = {
  setFetchStatus: (state, status) => {
    state.fetchStatus = status;
  },
  setMaterialGroups: (state, materialGroups) => {
    state.storedMaterialGroups = materialGroups;
  },
};

const actions = {
  fetchMaterialGroups: async ({ commit, rootGetters }) => {
    try {
      commit('setFetchStatus', 'loading');
      const response = await request(
        'GET',
        'api/material-groups',
        rootGetters['auth/getToken'],
        {},
        {},
      );
      setTimeout(commit('setMaterialGroups', response.data), 250);
      commit('setFetchStatus', 'success');
      return Promise.resolve();
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  fetchMaterialGroupsUnauthorized: async ({ commit }) => {
    try {
      commit('setFetchStatus', 'loading');
      const response = await request(
        'GET',
        'api/common/material-groups',
      );
      setTimeout(commit('setMaterialGroups', response.data), 250);
      commit('setFetchStatus', 'success');
      return Promise.resolve();
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
};
const modules = {};
const state = {
  fetchStatus: '',
  storedMaterialGroups: {},
};

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  modules,
  state,
};
