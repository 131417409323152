import { cloneDeep } from 'lodash';
import request from '@/utils/requests';

const getters = {
  isEditMode: (state) => state.mode === 'EDIT',

  isCreateMode: (state) => state.mode === 'CREATE',

  getMode: (state) => state.mode,

  getEditedMaterial: (state) => state.editedMaterial,

  getEditedMaterialTitle: (state) => state.editedMaterial.title,

  getEditedMaterialDescription: (state) => state.editedMaterial.description,

  getMaterialGroupId: (state) => state.editedMaterial.material_group_id,

  getEditedMaterialFrequency: (state) => state.editedMaterial.frequency,

  getEditedMaterialAddressId: (state) => state.editedMaterial.address_id,

  getEditedMaterialQuantity: (state) => state.editedMaterial.quantity,

  getEditedMaterialImage: (state) => state.editedMaterial.image_url,

  getEditedMaterialSdgGoals: (state) => state.updatedSdgGoals,

  getUpdatedImage: (state) => state.editedMaterial.image,

  getMaterialToSave: (state) => {
    const material = cloneDeep(state.editedMaterial);
    const updatedImage = cloneDeep(state.updatedImage);
    const updatedSdgGoals = cloneDeep(state.updatedSdgGoals);

    if (updatedImage) {
      material.image = {
        body: updatedImage.body,
        name: updatedImage.name,
      };
      delete material.image_url;
      delete material.image_name;
    }

    material.sdg_goals = updatedSdgGoals;

    return material;
  },
};
const mutations = {
  SET_MODE: (state, mode) => {
    state.mode = mode;
  },

  SET_EDITED_MATERIAL: (state, editedMaterial) => {
    state.editedMaterial = editedMaterial;

    if (editedMaterial?.sdg_goals) {
      state.updatedSdgGoals = editedMaterial.sdg_goals.map((i) => i.id);
    }
  },

  SET_EDITED_MATERIAL_TITLE: (state, title) => {
    state.editedMaterial.title = title;
  },

  SET_EDITED_MATERIAL_DESCRIPTION: (state, description) => {
    state.editedMaterial.description = description;
  },

  SET_EDITED_MATERIAL_GROUP_ID: (state, materialGroupId) => {
    state.editedMaterial.material_group_id = materialGroupId;
  },

  SET_EDITED_MATERIAL_FREQUENCY: (state, frequency) => {
    state.editedMaterial.frequency = frequency;
  },

  SET_EDITED_MATERIAL_ADDRESS_ID: (state, addressId) => {
    state.editedMaterial.address_id = addressId;
  },

  SET_EDITED_MATERIAL_QUANTITY: (state, quantity) => {
    state.editedMaterial.quantity = parseInt(quantity, 10);
  },

  SET_EDITED_MATERIAL_SDG_GOALS: (state, sdgGoals) => {
    state.updatedSdgGoals = sdgGoals;
  },

  SET_UPDATED_IMAGE: (state, imageData) => {
    state.updatedImage = imageData;
  },
};
const actions = {
  startEditMode: ({ commit }) => {
    commit('SET_MODE', 'EDIT');
  },

  startCreateMode: ({ commit }) => {
    commit('SET_MODE', 'CREATE');
  },

  setEditedMaterial: ({ commit }, editedMaterial) => {
    commit('SET_EDITED_MATERIAL', editedMaterial);
  },

  setEditedMaterialTitle: ({ commit }, title) => {
    commit('SET_EDITED_MATERIAL_TITLE', title);
  },

  setEditedMaterialDescription: ({ commit }, description) => {
    commit('SET_EDITED_MATERIAL_DESCRIPTION', description);
  },

  setEditedMaterialMaterialGroupId: ({ commit }, materialGroupId) => {
    commit('SET_EDITED_MATERIAL_GROUP_ID', materialGroupId);
  },

  setEditedMaterialFrequency: ({ commit }, frequency) => {
    commit('SET_EDITED_MATERIAL_FREQUENCY', frequency);
  },

  setEditedMaterialAddressId: ({ commit }, addressId) => {
    commit('SET_EDITED_MATERIAL_ADDRESS_ID', addressId);
  },

  setEditedMaterialSdgGoals: ({ commit }, sdgGoals) => {
    commit('SET_EDITED_MATERIAL_SDG_GOALS', sdgGoals);
  },

  setEditedMaterialQuantity: ({ commit }, quantity) => {
    commit('SET_EDITED_MATERIAL_QUANTITY', quantity);
  },

  setUpdatedImage: ({ commit }, imageData) => {
    commit('SET_UPDATED_IMAGE', imageData);
  },

  close: ({ commit }) => {
    commit('SET_MODE', null);
    commit('SET_UPDATED_IMAGE', null);
    commit('SET_EDITED_MATERIAL', {
      title: '',
      description: '',
      material_group_id: '',
      frequency: {},
      address_id: null,
      image: {},
      sdg_goals: [],
      quantity: null,
    });
  },

  // eslint-disable-next-line no-shadow
  updateMaterial: async ({ getters, rootGetters, dispatch }) => {
    const material = getters.getMaterialToSave;
    const companyId = rootGetters['auth/getUserCompanyId'];

    try {
      const response = await request(
        'PUT',
        `api/materials/${material.id}`,
        rootGetters['auth/getToken'],
        material,
        { company_id: companyId, uuid: material.uuid },
      );
      dispatch('materials/setShouldRefetchMaterials', true, { root: true });
      dispatch('close');
      return Promise.resolve(response.data);
    } catch (e) {
      return Promise.reject(e);
    }
  },

  // eslint-disable-next-line no-shadow
  createMaterial: async ({ getters, rootGetters, dispatch }) => {
    const material = getters.getMaterialToSave;
    const companyId = rootGetters['auth/getUserCompanyId'];

    try {
      const response = await request(
        'POST',
        'api/materials',
        rootGetters['auth/getToken'],
        material,
        { company_id: companyId },
      );
      dispatch('materials/setShouldRefetchMaterials', true, { root: true });
      dispatch('close');
      return Promise.resolve(response.data);
    } catch (e) {
      return Promise.reject(e);
    }
  },
};

const modules = {};
const state = {
  mode: null,
  editedMaterial: {
    title: '',
    description: '',
    material_group_id: '',
    frequency: {},
    address_id: null,
    image_url: '',
    sdg_goals: [],
    quantity: null,
  },
  updatedImage: null,
  updatedSdgGoals: [],
};

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  modules,
  state,
};
