const axios = require('axios');

const liveRequest = async (method, endpoint, token, payload, queryParams) => {
  const options = {
    method,
    url: `${process.env.VUE_APP_API_URL}${endpoint}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
    data: payload,
    params: queryParams,
  };

  try {
    const response = await axios.request(options);
    return Promise.resolve(response);
  } catch (e) {
    console.log(e);
    return Promise.reject(e);
  }
};

export default liveRequest;
