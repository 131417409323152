import Vue from 'vue';
import format from 'date-fns/format';

Vue.filter('formatToDaysAgo', (date) => {
  const today = new Date(Date.now());
  const secondDate = new Date(date);
  const MS_PER_DAY = 1000 * 60 * 60 * 24;

  const todayDay = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
  const reviewDay = Date.UTC(
    secondDate.getFullYear(),
    secondDate.getMonth(),
    secondDate.getDate(),
  );

  const daysAgo = Math.floor((todayDay - reviewDay) / MS_PER_DAY);

  if (daysAgo === 0) {
    return 'Today';
  }

  if (daysAgo <= 30) {
    return `${daysAgo} days ago`;
  }

  if (daysAgo > 30 && daysAgo <= 365) {
    return `${Math.floor(daysAgo / 30)} month${Math.floor(daysAgo / 30) > 1 ? 's' : ''} ago`;
  }

  if (daysAgo > 365) {
    return `${Math.floor(daysAgo / 365)} year${Math.floor(daysAgo / 365) > 1 ? 's' : ''} ago`;
  }
});

Vue.filter('formatDate', (isoDate, dateFormat) => {
  if (!isoDate) {
    return;
  }
  return format(new Date(isoDate), dateFormat);
});
