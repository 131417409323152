import { cloneDeep } from 'lodash';
import request from '@/utils/requests';

const getters = {
  isEditingMode: (state) => state.isEditingMode,

  isAddingNewLocation: (state) => state.isAddingNewLocation,

  getEditingCompany: (state) => state.editingCompany,

  getUpdatedImage: (state) => state.updatedImage,

  getCompanyAddresses: (state) => state.editingCompany.addresses,

  getCompanyToSave: (state) => {
    const company = cloneDeep(state.editingCompany);

    company.materials_interested = company.materials_interested.map((item) => item.id);
    company.materials_providing = company.materials_providing.map((item) => item.id);

    if (!company.country_calling_code) {
      delete company.phone;
    }

    // eslint-disable-next-line dot-notation
    const updatedImage = cloneDeep(state.updatedImage);
    if (updatedImage) {
      company.image = updatedImage;
      delete company.image_url;
      delete company.image_name;
    }

    return company;
  },
};

const mutations = {
  SET_EDITING_MODE: (state, isEditingMode) => {
    state.isEditingMode = isEditingMode;
  },

  SET_IS_ADDING_NEW_LOCATION: (state, isAddingNewLocation) => {
    state.isAddingNewLocation = isAddingNewLocation;
  },

  SET_EDITING_COMPANY: (state, company) => {
    state.editingCompany = company;
  },

  SET_EDITING_COMPANY_ADDRESSES: (state, companyAddresses) => {
    state.editingCompany.addresses = companyAddresses;
  },

  SET_COMPANY_IMAGE: (state, image) => {
    state.updatedImage = image;
  },

  SET_PHONE_COUNTRY_CALLING_CODE: (state, countryCallingCode) => {
    state.editingCompany.country_calling_code = countryCallingCode;
  },

  SET_PHONE_NUMBER: (state, phone) => {
    state.editingCompany.phone = phone;
  },

  SET_COMPANY_SIZE: (state, companySize) => {
    state.editingCompany.company_size = companySize;
  },

  SET_COMPANY_ABOUT_INFO: (state, aboutInfo) => {
    state.editingCompany.about_info = aboutInfo;
  },

  SET_COMPANY_PROVIDING_MATERIALS: (state, providingMaterials) => {
    state.editingCompany.materials_providing = providingMaterials;
  },

  SET_COMPANY_INTERESTED_MATERIALS: (state, interestedMaterials) => {
    state.editingCompany.materials_interested = interestedMaterials;
  },
};

const actions = {
  startEditingMode: async ({ commit, rootGetters, dispatch }) => {
    const userCompanyId = rootGetters['auth/getUserCompanyId'];
    const company = await dispatch(
      'companies/fetchCompanyById',
      { id: userCompanyId },
      { root: true },
    );

    commit('SET_EDITING_COMPANY', company);
    commit('SET_EDITING_MODE', true);
  },

  endEditingMode: ({ commit, dispatch }) => {
    commit('SET_EDITING_COMPANY', {});
    commit('SET_COMPANY_IMAGE', null);
    commit('SET_EDITING_MODE', false);
    dispatch('endAddNewLocation');
  },

  startAddNewLocation: ({ commit }) => {
    commit('SET_IS_ADDING_NEW_LOCATION', true);
  },

  endAddNewLocation: ({ commit }) => {
    commit('SET_IS_ADDING_NEW_LOCATION', false);
  },

  setCompanyAddresses: ({ commit }, addresses) => {
    commit('SET_EDITING_COMPANY_ADDRESSES', addresses);
  },

  setUpdatedImage: ({ commit }, imageData) => {
    commit('SET_COMPANY_IMAGE', imageData);
  },

  setCompanyPhone: ({ commit }, { countryCode, number }) => {
    commit('SET_PHONE_COUNTRY_CALLING_CODE', countryCode);
    commit('SET_PHONE_NUMBER', number);
  },

  setCompanySize: ({ commit }, companySize) => {
    commit('SET_COMPANY_SIZE', companySize);
  },

  setCompanyAboutInfo: ({ commit }, aboutInfo) => {
    commit('SET_COMPANY_ABOUT_INFO', aboutInfo);
  },

  setCompanyProvidingMaterials: ({ commit }, providingMaterials) => {
    commit('SET_COMPANY_PROVIDING_MATERIALS', providingMaterials);
  },

  setCompanyInterestedMaterials: ({ commit }, interestedMaterials) => {
    commit('SET_COMPANY_INTERESTED_MATERIALS', interestedMaterials);
  },

  // eslint-disable-next-line no-shadow
  saveCompanyInfo: async ({ dispatch, getters, rootGetters }) => {
    // eslint-disable-next-line dot-notation
    const company = getters['getCompanyToSave'];
    console.log(company);
    try {
      await request(
        'PUT',
        `api/companies/${company.id}`,
        rootGetters['auth/getToken'],
        company,
        {},
      );
      dispatch('endEditingMode');
      await dispatch('auth/fetchUserCompany', '', { root: true });
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  },
};

const modules = {};

const state = {
  isEditingMode: false,
  isAddingNewLocation: false,
  editingCompany: {},
  updatedImage: null,
};

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  modules,
  state,
};
