<template>
  <v-banner color="primary" sticky>
    <div class="d-flex flex-row flex-wrap justify-center align-center font-family-montserrat">
      <p class="font-weight-regular ma-0">
        This is a limited functionality demo app for training purpose only!
        Inquire with us to gain full access at
        <a
          href="https://www.thesurpluss.com/"
          target="_blank"
          rel="noopener noreferrer"
          class="font-weight-medium"
          style="color: black !important;"
        >
          thesurpluss.com
        </a>
      </p>
    </div>
  </v-banner>
</template>

<script>

export default {
  name: 'DemoBanner',
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>
