import request from '@/utils/requests';

const getters = {
  getActiveChatsList: (state) => state.activeChatsList,
  getCurrentChat: (state) => state.currentChat,
};

const mutations = {
  setActiveChats: (state, chats) => {
    state.activeChatsList = chats;
  },
  setCurrentChat: (state, chat) => {
    state.currentChat = chat;
  },
};

const actions = {
  fetchActiveChatsList: async ({ commit, rootGetters }, payload) => {
    try {
      const response = await request(
        'GET',
        'api/chats',
        rootGetters['auth/getToken'],
        {},
        payload.params,
      );
      commit('setActiveChats', response.data);
      return Promise.resolve(response);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  fetchChatByID: async ({ commit, rootGetters }, chat) => {
    try {
      const response = await request(
        'GET',
        `api/chats/${chat.id}`,
        rootGetters['auth/getToken'],
        {},
        {},
      );
      commit('setCurrentChat', response.data);
      return Promise.resolve(response);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  fetchChatAttachments: async ({ rootGetters }, chat) => {
    try {
      const response = await request(
        'GET',
        `api/chats/${chat.id}/attachments`,
        rootGetters['auth/getToken'],
        {},
        {},
      );
      return Promise.resolve(response.data);
    } catch (e) {
      return Promise.reject(e);
    }
  },
};

const modules = {};

const state = {
  activeChatsList: {},
  currentChat: {},
};

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  modules,
  state,
};
