import { isEmpty } from 'lodash';
import demoData from '../../../demo.json';

export const handleNewMaterial = (payload) => {
  const sdgGoals = payload.sdg_goals.map((id) => {
    const goal = demoData['api/companies/1/sdg-exchanges'].find((g) => g.id === id);
    return { ...goal };
  });

  const frequency = {};
  if ('one off' in payload.frequency) { frequency['One-off'] = true; }
  if ('daily' in payload.frequency) { frequency.Daily = true; }
  if ('weekly' in payload.frequency) { frequency.Weekly = true; }
  if ('monthly' in payload.frequency) { frequency.Monthly = true; }
  if ('every few months' in payload.frequency) { frequency['Every few months'] = true; }

  // eslint-disable-next-line no-param-reassign
  delete payload.material_group_id;
  // eslint-disable-next-line no-param-reassign
  delete payload.sdg_goals;
  // eslint-disable-next-line no-param-reassign
  delete payload.quantity;
  // eslint-disable-next-line no-param-reassign
  delete payload.frequency;

  const newMaterial = {
    id: Date.now(),
    active: true,
    image_url: null,
    image_name: null,
    created_at: new Date().toISOString(),
    material_group_id: 11,
    company_id: demoData.newMaterialCompany.id,
    sdg_goals: sdgGoals,
    frequency,
    ...payload,
    company: demoData.newMaterialCompany,
    address: demoData.newMaterialAddress,
  };

  demoData['api/materials'].items.push(newMaterial);
  demoData[`api/materials?company_id=${demoData.newMaterialCompany.id}`].items.push(newMaterial);
  demoData[`api/materials/${newMaterial.id}`] = newMaterial;
};

export const handleNewUser = (payload) => {
  const newUser = {
    id: Date.now(),
    ...payload,
    image_url: null,
    image_name: null,
    email_subscription: true,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    deleted_at: null,
    company_id: demoData.newMaterialCompany.id,
    company: demoData.newMaterialCompany,
  };

  demoData['api/users/'].items.push(newUser);
  demoData[`api/users/${newUser.id}`] = newUser;
};

export const handleNewMessage = (payload, oldMessages) => {
  const currentChat = demoData.activeChats.find((chat) => chat.id === payload.chat_id);
  const newMessages = [
    {
      id: Date.now(),
      message: payload.message,
      attachment: payload?.attachment?.body,
      attachment_name: payload?.attachment?.name,
      attachment_size: null,
      seen: true,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      deleted_at: null,
      user_id: 1,
      user: demoData['api/users/1'],
    },
    {
      id: Date.now() + 1,
      message: 'Thank you for contacting us. We will get back to you shortly. We are looking forward to working with you!',
      attachment: null,
      attachment_name: null,
      attachment_size: null,
      seen: true,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      deleted_at: null,
      user_id: currentChat.receiving_user_id,
      user: currentChat.receiving_user,
    },
  ];

  demoData[`chat-messages/${payload.chat_id}`] = [
    ...demoData[`chat-messages/${payload.chat_id}`],
    ...newMessages,
  ];

  if (payload.attachment) {
    const mediaExtensions = new Set(['png', 'jpg', 'jpeg', 'webp']);
    const fileExtension = payload.attachment.name.split('.').pop();
    const isMedia = mediaExtensions.has(fileExtension);
    const newAttachment = {
      url: `data:${isMedia ? 'image/png' : 'application/octate-stream'};base64,${payload.attachment.body}`,
      name: payload.attachment.name,
      size: null,
      date_time: new Date().toISOString(),
    };
    if (isMedia) {
      demoData[`api/chats/${payload.chat_id}/attachments`].media.push(newAttachment);
    } else {
      demoData[`api/chats/${payload.chat_id}/attachments`].documents.push(newAttachment);
    }
  }

  return [
    ...oldMessages,
    ...newMessages,
  ];
};

export const handleEndSynergy = (updatedExhange) => {
  demoData['api/exchanges'].items = demoData['api/exchanges'].items.map((exchange) => {
    if (exchange.id === updatedExhange.id) {
      return {
        ...exchange,
        level_of_completion: updatedExhange.level_of_completion,
        successful: updatedExhange.successful,
      };
    }
    return exchange;
  });

  let endedChat;
  const newActiveChats = demoData.activeChats.filter((chat) => {
    if (chat.exchange_id === updatedExhange.id) {
      endedChat = chat;
      return false;
    }
    return true;
  });
  endedChat.active = false;
  demoData.activeChats = newActiveChats;
  demoData.inactiveChats = [endedChat, ...demoData.inactiveChats];
};

export const handleNewReview = (payload) => {
  const newReview = {
    id: Date.now(),
    ...payload.review,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    deleted_at: null,
    exchange_id: payload.exchange.id,
    company_id: 1,
    companyId: 1,
    company: demoData['api/companies/1'],
    exchange: payload.exchange,
  };

  demoData['api/companies/1/reviews'].items.push(newReview);
  demoData[`api/exchanges/${payload.exchange.id}/feedback/${newReview.id}`] = newReview;
};

export const handleMaterialsFiltering = (frequency, map, geoloc, page) => {
  let materials = [...demoData['api/materials'].items];
  if (map) {
    const locationObjectArray = geoloc.split(',').map((loc) => {
      const locArr = loc.split('x');
      return { lat: parseFloat(locArr[0]), long: parseFloat(locArr[1]) };
    });
    const geolocation = { sw: { ...locationObjectArray[0] }, ne: { ...locationObjectArray[1] } };
    const { lat: latitudeMin, long: longitudeMin } = geolocation.sw;
    const { lat: latitudeMax, long: longitudeMax } = geolocation.ne;

    materials = materials.filter((material) => {
      const latFlag = (material.address.location_latitude >= latitudeMin
        && material.address.location_latitude <= latitudeMax);
      const longFlag = (material.address.location_longitude >= longitudeMin
        && material.address.location_longitude <= longitudeMax);
      return latFlag && longFlag;
    });
  }

  if (!isEmpty(frequency)) {
    const frequencyQuery = {};
    if (frequency.includes('One-off') || frequency === 'One-off') { frequencyQuery['One-off'] = true; }
    if (frequency.includes('Daily') || frequency === 'Daily') { frequencyQuery.Daily = true; }
    if (frequency.includes('Weekly') || frequency === 'Weekly') { frequencyQuery.Weekly = true; }
    if (frequency.includes('Monthly') || frequency === 'Monthly') { frequencyQuery.Monthly = true; }
    if (frequency.includes('Every few months') || frequency === 'Every few months') { frequencyQuery['Every few months'] = true; }

    materials = materials.filter(
      (material) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in frequencyQuery) {
          if (frequencyQuery[key] === material.frequency[key]) {
            return true;
          }
        }
        return false;
      },
    );
  }

  if (materials.length > 10 && typeof page !== 'undefined') {
    const startIndex = page * 10;
    return {
      data: {
        total: materials.length,
        totalPages: Math.ceil(materials.length / 10),
        currentPage: page,
        items: materials.slice(startIndex, startIndex + 10),
      },
    };
  }

  return {
    data: {
      total: materials.length,
      totalPages: 1,
      currentPage: 0,
      items: materials,
    },
  };
};
