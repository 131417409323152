import createUser from '@/store/modules/user/createUser';
import deleteUser from '@/store/modules/user/deleteUser';
import request from '@/utils/requests';
import { cloneDeep as _cloneDeep } from 'lodash';

const getters = {
  getPublicUser: (state) => state.publicUser,

  getCompanyUsers: (state) => _cloneDeep(state.companyUsers),

  getMode: (state) => state.editedUser.mode,

  getEditedUser: (state) => state.editedUser.object,

  getEditedUserId: (state) => state.editedUser.id,

  getEditedUserImage: (state) => state.image,

  getCompanyUserById: (state) => (id) => _cloneDeep(state.companyUsers.find((e) => e.id === id)),

  getRoles: (state) => state.roles,

  isCreateMode: (state) => state.editedUser.mode === 'CREATE',

  shouldRefetchUsers: (state) => state.shouldRefetchUsers,
};

const mutations = {
  setCompanyUsers: (state, companyUsers) => {
    companyUsers.items.forEach((user) => {
      if (user.role === 'MANAGER') {
        // eslint-disable-next-line no-param-reassign
        user.role = 'AMBASSADOR';
      }
    });
    state.companyUsers = companyUsers;
  },

  SET_EDITED_USER_IMAGE: (state, image) => {
    state.image = image;
  },

  SET_EDITED_USER: (state, data) => {
    if (data.object) {
      const currentUser = data.object;
      if (currentUser.role === 'AMBASSADOR') {
        currentUser.role = 'MANAGER';
      }
    }
    state.editedUser = data;
  },

  SET_MODE: (state, mode) => {
    state.editedUser.mode = mode;
  },

  SET_SHOULD_REFETCH_USERS: (state, shouldRefetchUsers) => {
    state.shouldRefetchUsers = shouldRefetchUsers;
  },
};

const actions = {
  fetchCompanyUsers: async ({ rootGetters, commit }, params) => {
    try {
      const response = await request(
        'GET',
        'api/users/',
        rootGetters['auth/getToken'],
        {},
        params,
      );
      commit('SET_SHOULD_REFETCH_USERS', false);
      commit('setCompanyUsers', response.data);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  },

  fetchUserById: async ({ rootGetters }, payload) => {
    try {
      const response = await request(
        'GET',
        `api/users/${payload.user.id}`,
        rootGetters['auth/getToken'],
        {},
        {},
      );

      return Promise.resolve(response);
    } catch (e) {
      return Promise.reject(e);
    }
  },

  updateUser: async ({ rootGetters }, payload) => {
    try {
      const response = await request(
        'PUT',
        `api/users/${payload.user.id}`,
        rootGetters['auth/getToken'],
        payload.user,
        payload.params,
      );

      const userToken = JSON.parse(localStorage.getItem('user-token'));
      const previousUserInfo = userToken.user;

      userToken.user = { ...previousUserInfo, ...response.data };
      localStorage.setItem('user-token', JSON.stringify(userToken));
      return Promise.resolve(response);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },

  changePassword: async ({ rootGetters }, payload) => {
    try {
      const response = await request(
        'POST',
        'api/users/change-password',
        rootGetters['auth/getToken'],
        payload.newPassword,
        {},
      );
      return Promise.resolve(response);
    } catch (e) {
      return Promise.reject(e);
    }
  },

  startEditMode: ({ commit }) => {
    commit('SET_MODE', 'EDIT');
  },

  // eslint-disable-next-line no-shadow
  setEditedUser: ({ commit }, userData) => {
    const u = getters.getCompanyUserById(userData.id);
    const editedUser = {
      id: userData.id,
      mode: 'EDIT',
      object: { ...u, ...userData.object },
    };
    commit('SET_EDITED_USER', editedUser);
  },

  updateEditedUser: async ({ rootGetters, dispatch, commit }) => {
    if (rootGetters['users/getEditedUserId'] === -1) {
      return Promise.resolve();
    }

    if (rootGetters['users/getMode'] !== 'EDIT') {
      return Promise.resolve();
    }

    const user = rootGetters['users/getEditedUser'];
    const params = { company_id: user.company_id, uuid: user.uuid };
    const token = rootGetters['auth/getToken'];

    try {
      await request('PUT', `api/users/${user.id}`, token, user, params);
      commit('SET_SHOULD_REFETCH_USERS', true);
      dispatch('clearEditedUser');
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  },

  setEditedUserImage: ({ commit }, image) => {
    commit('SET_EDITED_USER_IMAGE', image);
  },

  clearEditedUser: ({ commit }) => {
    commit('SET_EDITED_USER', { id: -1, mode: '', object: null });
  },

  setShouldRefetchUsers: ({ commit }, shouldRefetchUsers) => {
    commit('SET_SHOULD_REFETCH_USERS', shouldRefetchUsers);
  },
};

const modules = {
  create: createUser,
  delete: deleteUser,
};

const state = {
  publicUser: {},
  companyUsers: [],
  shouldRefetchUsers: false,
  image: null,
  editedUser: { id: -1, mode: '', object: null },
  roles: [
    {
      text: 'AMBASSADOR',
      value: 'MANAGER',
    },
    {
      text: 'MEMBER',
      value: 'MEMBER',
    },
  ],
};

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  modules,
  state,
};
