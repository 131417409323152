<template>
  <v-snackbar
    top
    right
    :color="color"
    :timeout="timeout"
    :app="app"
    :multi-line="multiline"
    v-model="appear"
    transition="slide-x-reverse-transition"
  >
    {{text}}
  </v-snackbar>
</template>

<script>
export default {
  name: 'SnackBar',
  data() {
    return {
      appear: false,
      multiline: false,
      app: true,
      color: 'success',
      text: '',
      icon: '',
      timeout: 3000,
    };
  },
  methods: {
    show(data) {
      this.appear = data.appear || false;
      this.multiline = data.multiline || false;
      this.app = data.app || true;
      this.color = data.color || 'success';
      this.text = data.text || 'no message';
      this.timeout = data.timeout || 3000;
    },
  },
};
</script>

<style scoped>

</style>
