import request from '@/utils/requests';

const getters = {
  getAverageRating: (state) => state.averageRating,
  getTotal: (state) => state.total,
  getItems: (state) => state.items,
  getTotalPages: (state) => state.totalPages,
  getCurrentPage: (state) => state.currentPage,
  getIsViewingReviewDetails: (state) => state.isViewingReviewDetails,
};

const mutations = {
  SET_AVERAGE_RATING: (state, averageRating) => {
    state.averageRating = averageRating;
  },

  SET_TOTAL: (state, total) => {
    state.total = total;
  },

  SET_ITEMS: (state, items) => {
    state.items = items;
  },

  SET_TOTAL_PAGES: (state, totalPages) => {
    state.totalPages = totalPages;
  },

  SET_CURRENT_PAGE: (state, currentPage) => {
    state.currentPage = currentPage;
  },

  SET_IS_VIEWING_REVIEW_DETAILS: (state, isViewingReviewDetails) => {
    state.isViewingReviewDetails = isViewingReviewDetails;
  },
};

const modules = {};

const actions = {
  fetchCompanyReviews: async ({ rootGetters, commit }, { companyId, page, size }) => {
    try {
      const response = await request(
        'GET',
        `api/companies/${companyId}/reviews`,
        rootGetters['auth/getToken'],
        {},
        { page, size },
      );

      const { data } = response;
      commit('SET_AVERAGE_RATING', data.average_rating);
      commit('SET_TOTAL', data.total);
      commit('SET_ITEMS', data.items);
      commit('SET_TOTAL_PAGES', data.totalPages);
      commit('SET_CURRENT_PAGE', data.currentPage);

      return Promise.resolve(data);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },

  setIsViewingReviewDetails: ({ commit }, isViewingDetails) => {
    commit('SET_IS_VIEWING_REVIEW_DETAILS', isViewingDetails);
  },
};

const state = {
  averageRating: 0,
  total: null,
  items: [],
  totalPages: null,
  currentPage: null,
  isViewingReviewDetails: false,
};

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  modules,
  state,
};
