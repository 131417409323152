import i18n from '../../plugins/i18n';

const getters = {
  currentLanguage(state) {
    return state.language;
  },
};

const mutations = {
  SET_LANGUAGE(state, lang) {
    state.language = lang;
    localStorage.setItem('language', lang);
    i18n.locale = lang;
  },
};

const actions = {
  setLanguage({ commit }, lang) {
    commit('SET_LANGUAGE', lang);
  },
};

const state = {
  language: localStorage.getItem('language') || 'en',
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
