<template>
  <v-btn
      icon
      plain
      :to="{name: routeName}"
      class="mr-4 mt-2"
  >
    <v-badge
        :value="badgeEnabled"
        bordered
        dot
        overlap
        color="error"
    >
      <v-hover v-slot="{ hover }">
        <v-icon
            :color="hover ? 'white' : 'grey'"
            size="22"
        >
          {{ iconName }}
        </v-icon>
      </v-hover>
    </v-badge>
  </v-btn>
</template>

<script>
export default {
  name: 'CustomNavButton',
  props: ['routeName', 'badgeEnabled', 'iconName'],
};
</script>

<style lang="scss" scoped>
.v-btn--active > span > span > i {
  color: white !important;
}
</style>
