import request from '@/utils/requests';
import companies from '@/store/modules/admin/company/companies';

const getters = {
  fetchStatus: (state) => state.status,
  getTempAccounts: (state) => state.tempAccounts,
  getOfferings: (state) => state.offerings,
  getMaterialGroups: (state) => state.materialGroups,
  getTempAccountsStats: (state) => state.tempAccountsStats,
  getRecommendationsDisplayedCount: (state) => state.recommendationsDisplayedCount,
};
const mutations = {
  fetchRequest: (state) => {
    state.status = 'loading';
  },
  fetchSuccess: (state) => {
    state.status = 'success';
  },
  setTempAccounts: (state, tempAccounts) => {
    state.tempAccounts = tempAccounts;
  },
  fetchError: (state) => {
    state.status = 'error';
  },
  setStatus: (state, status) => {
    state.status = status;
  },
  setOfferings: (state, offerings) => {
    state.offerings = offerings;
  },
  setMaterialGroups: (state, materialGroups) => {
    state.materialGroups = materialGroups;
  },
  setTempAccountsStats: (state, tempAccountsStats) => {
    state.tempAccountsStats = tempAccountsStats;
  },
  setRecommendationsDisplayedCount: (state, recommendationsDisplayedCount) => {
    state.recommendationsDisplayedCount = recommendationsDisplayedCount;
  },
};
const actions = {
  fetchTempAccounts: async ({ commit, rootGetters }) => {
    try {
      commit('setTempAccounts', []);
      commit('fetchRequest');
      const response = await request(
        'GET',
        'admin/temporary-accounts',
        rootGetters['auth/getToken'],
        {},
        {},
      );
      setTimeout(commit('setTempAccounts', response.data), 250);
      commit('fetchSuccess');
      return Promise.resolve();
    } catch (e) {
      console.log(e);
      commit('fetchError');
      return Promise.reject();
    }
  },
  reviewTempAccount: async ({ commit, rootGetters }, payload) => {
    try {
      commit('setStatus', 'loading');
      const response = await request(
        'POST',
        'admin/temporary-accounts/review',
        rootGetters['auth/getToken'],
        payload,
        {},
      );
      commit('setStatus', 'success');
      return Promise.resolve(response);
    } catch (e) {
      console.log(e);
      commit('setStatus', 'error');
      return Promise.reject(e);
    }
  },
  fetchOfferings: async ({ commit, rootGetters }, payload) => {
    try {
      commit('setOfferings', {});
      const response = await request(
        'GET',
        'admin/materials',
        rootGetters['auth/getToken'],
        {},
        payload.params,
      );
      commit('setOfferings', response.data);
      return Promise.resolve();
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  fetchMaterialGroups: async ({ commit, rootGetters }) => {
    try {
      commit('fetchRequest');
      const response = await request(
        'GET',
        'admin/material-groups',
        rootGetters['auth/getToken'],
        {},
        {},
      );
      commit('setMaterialGroups', response.data);
      commit('fetchSuccess');
      return Promise.resolve();
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  fetchSynergiesStats: async ({ rootGetters }, payload) => {
    try {
      const response = await request(
        'GET',
        'admin/exchanges/stats',
        rootGetters['auth/getToken'],
        {},
        payload.params,
      );
      return Promise.resolve(response);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  fetchMaterialsStats: async ({ rootGetters }) => {
    try {
      const response = await request(
        'GET',
        'admin/materials/stats',
        rootGetters['auth/getToken'],
      );
      return Promise.resolve(response);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  fetchTempAccountsStats: async ({ rootGetters }) => {
    try {
      const response = await request(
        'GET',
        'admin/temporary-accounts/stats',
        rootGetters['auth/getToken'],
      );
      return Promise.resolve(response.data);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  fetchTempAccountsWithinTimePeriod: async ({ rootGetters }, { startDate, endDate }) => {
    try {
      const queryParams = {};
      if (startDate) {
        queryParams.start_date = startDate;
      }
      if (endDate) {
        queryParams.end_date = endDate;
      }
      const response = await request(
        'GET',
        'admin/temporary-accounts/stats',
        rootGetters['auth/getToken'],
        {},
        queryParams,
      );
      return Promise.resolve(response.data);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  fetchRecommendationsDisplayedCount: async ({ commit, rootGetters }) => {
    try {
      commit('setRecommendationsDisplayedCount', null);
      const response = await request(
        'GET',
        'admin/total-recommendations-clicks',
        rootGetters['auth/getToken'],
      );
      setTimeout(commit('setRecommendationsDisplayedCount', response.data.total_clicks), 250);
      return Promise.resolve(response);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
};
const modules = { companies };
const state = {
  tempAccounts: {},
  status: '',
  offerings: {},
  materialGroups: {},
  tempAccountsStats: {},
};

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  modules,
  state,
};
